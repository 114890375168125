<template>
  <div style="width: 100% ;padding: 10px">
<!--    <div style="margin: 10px 0">
      <el-input v-model="search" placeholder="请输入图纸号" style="width:35%"></el-input>
      <el-button type="primary" style="margin-left:5px" @click = "selectkey">查询</el-button>
      <el-button type="primary" @click="remove">重置</el-button>
    </div>-->
    <div style="margin: 10px 0">
      <el-select v-model="process" filterable :placeholder="processList[0]" @change="select(process),currentPage4 = 1">
        <el-option
            v-for="item in processList"
            :key="item"
            :label="item"
            :value="item">
        </el-option>
      </el-select>
    </div>
    <el-table
        :data="tableData"
        border
        style="width: 80%">
      <el-table-column
          prop="id"
          v-if="false"
          label="id">
      </el-table-column>
      <el-table-column
          prop="tuzhihao"
          label="图纸号">
      </el-table-column>
      <el-table-column
          prop="xinghao"
          label="型号">
      </el-table-column>
      <el-table-column
          prop="gongxuName"
          label="工序">
      </el-table-column>
      <el-table-column
          prop="gongxuTap"
          label="工序标签">
      </el-table-column>
      <el-table-column
          prop="num"
          label="加工数量">
        <template slot-scope="scope">
          <el-button @click="up(scope.row)" type="text" size="small">{{scope.row.num}}</el-button>
        </template>
      </el-table-column>
      <el-table-column
          label="操作">
        <template slot-scope="prop">
          <el-button @click="xiangqing(prop.row)" type="text" size="small">展开详细信息</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin: 10px 0">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[5, 10, 20]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>

    <!--  详情  -->
    <el-dialog :visible.sync="XQshow" width="50%" :before-close="handleClose">
      <el-form :model="xiangqingform" label-width="80px">
        <el-form-item label="图纸号: ">
          <el-button type="text" >{{xiangqingform.tuzhihao}}</el-button>
        </el-form-item>
        <el-form-item label="型号: ">
          <el-button type="text" >{{xiangqingform.xinghao}}</el-button>
        </el-form-item>
        <el-form-item label="工序: ">
          <el-button type="text" >{{xiangqingform.gongxuName}}: {{process}}</el-button>
        </el-form-item>
        <el-form-item label="加工详情">
          <el-card class="box-card" style="width: 300px">
            <div slot="header" class="clearfix">
              <span>加工信息</span>
              <el-button style="float: right; padding: 3px 0" @click="up(xiangqingform)" type="text">添加 / 编辑</el-button>
            </div>
            <el-table
                :data="xiangqingform.idAndNum"
                border>
              <el-table-column
                  prop="id"
                  label="员工"
                  style="width: 30px">
              </el-table-column>
              <el-table-column
                  prop="num"
                  label="加工数量"
                  style="width: 30px">
              </el-table-column>
            </el-table>
          </el-card>
        </el-form-item>
        <el-form-item label="废件详情">
          <el-card class="box-card" style="width: 500px">
            <div slot="header" class="clearfix">
              <span>废件信息</span>
              <el-button style="float: right; padding: 3px 0" type="text" @click="feijianAdd(xiangqingform)">添加</el-button>
            </div>
            <el-table
                :data="xiangqingform.feijian"
                border
                style="width: 90%">
              <el-table-column
                  prop="scrapLabels"
                  label="废件标签">
              </el-table-column>
              <el-table-column
                  prop="Noid"
                  v-if="false"
                  label="废件id">
              </el-table-column>
              <el-table-column
                  prop="scrapNumbers"
                  label="废件数量">
              </el-table-column>
              <el-table-column
                  prop="renyuanid"
                  label="加工人">
              </el-table-column>
              <el-table-column
                  prop="scrapNotes"
                  label="备注">
              </el-table-column>
              <el-table-column
                  label="操作">
                <template slot-scope="scope">
                  <el-button @click="NoUpDate(scope.row)" type="text" size="small">编辑</el-button>
                  <el-button @click="Nodel(scope.row.noid)" type="text" size="small">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--  弹窗  -->
    <el-dialog :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
      <el-form :model="form" label-width="80px">
        <el-form-item v-if="false" label="工件id">
          <el-input v-model="form.gongjianid" style="width: 90%"></el-input>
        </el-form-item>
        <el-form-item label="加工人">
          <el-select v-model="form.id" placeholder="请选择加工人" @change="$forceUpdate()">
            <el-option
                v-for="item in processUser"
                :key="item.id"
                :label="item.username"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="false" label="工序">
          <el-input v-model="form.gongxu" style="width: 90%"></el-input>
        </el-form-item>
        <el-form-item label="加工数量">
          <el-input v-model="num" style="width: 90%"></el-input>
        </el-form-item>
        <el-form-item v-if="tapList.length !== 0" label="工件标签">
          <el-select v-model="form.tap" placeholder="请选择" @change="$forceUpdate()">
            <el-option
                v-for="item in tapList"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <cmdb-progress ref="cmdbProgress" v-if="dialogVisible" :affiliation-id="form.gongjianid" :progress="process" affiliation-type="workpiece" :update-inventory="false"/>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save(num)">确 定</el-button>
      </span>
    </el-dialog>
    <!--  废件弹窗  -->
    <el-dialog :visible.sync="dialogVisible2" width="50%" :before-close="handleClose">
      <el-form :model="feijianform" label-width="80px">
        <el-form-item label="废件标签">
          <el-radio-group v-model="feijianform.scrapLabels">
            <el-radio label="自废"></el-radio>
            <el-radio label="料废"></el-radio>
            <el-radio label="其他"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="加工人" v-if="feijianform.scrapLabels != '料废'">
          <el-select v-model="feijianform.yuangongid" placeholder="请选择加工人" @change="$forceUpdate()">
            <el-option
                v-for="item in processScreptUser"
                :key="item.id"
                :label="item.username"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="process == '检查'" label="工序">
          <el-select v-model="feijianform.gongxu" placeholder="请选择工序" @change="$forceUpdate()">
            <el-option
                v-for="item in processList"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="废件数">
          <el-input v-model="feijianform.scrapNumbers" style="width: 90%"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="feijianform.scrapNotes" style="width: 90%"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="feijianSave">确 定</el-button>
      </span>
    </el-dialog>
    <!--  废件编辑弹窗  -->
    <el-dialog :visible.sync="NoUp" width="50%" :before-close="handleClose">
      <el-form :model="NoUpDateform" label-width="80px">
        <el-form-item label="废件标签">
          <el-radio-group v-model="NoUpDateform.scrapLabels">
            <el-radio label="自废"></el-radio>
            <el-radio label="料废"></el-radio>
            <el-radio label="其他"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="废件数">
          <el-input v-model="NoUpDateform.scrapNumbers" style="width: 90%"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="NoUpDateform.scrapNotes" style="width: 90%"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="NoUp = false">取 消</el-button>
        <el-button type="primary" @click="NoUpDateSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import request from "@/utils/request";
import {hideFullScreenLoading, showFullScreenLoading} from "../../public/loding";
import cmdbProgress from "@/views/element/cmdb_progress";

export default {
  components:{cmdbProgress},
  data() {
    return {
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
      size:10,
      search:'',
      process:'',
      processList:[],
      tapList:[],
      personlist:[],
      currentPage4:1,
      total:10,
      num:0,
      form:{},
      feijianform:{},
      NoUpDateform:{},
      resList:['自废','料废','其他'],
      tableData: [],
      dialogVisible: false,
      dialogVisible2: false,
      NoUp: false,
      XQshow: false,
      xiangqingform:{},
      processUser:[],//特定工序员工
      processScreptUser:[]//特定工序员工
    }
  },
  //加载表格
  mounted() {
    this.getPersonlist()
    this.selectProcess()
  },
  methods:{
    getUserByProcess(process){
      request.post("/getUserByProcess", process).then(res => {
        if (res.code == "200") {
          this.processUser = res.data;
          if (process == "检查"){
            request.post("/getAllWorkerList").then(res => {
              if (res.code == "200") {
                this.processScreptUser = res.data;
              } else {
                this.$notify.error({
                  title: '错误',
                  message: res.code + ":" + res.msg
                });
              }
            })
          } else {
            this.processScreptUser = res.data;
          }
        } else {
          this.$notify.error({
            title: '错误',
            message: res.code + ":" + res.msg
          });
        }
      })
    },
    //详情
    xiangqing(row){
      this.XQshow = true;
      this.xiangqingform = row;
      for (let i = 0; i < this.personlist.length ; i++) {
        for (let j = 0; j < this.xiangqingform.feijian.length; j++) {
          if (this.xiangqingform.feijian[j].renyuanid == this.personlist[i].id){
            this.xiangqingform.feijian[j].renyuanid = this.personlist[i].username;
          }
        }
        for (let j = 0; j < this.xiangqingform.idAndNum.length; j++) {
          if (this.xiangqingform.idAndNum[j].id == this.personlist[i].id){
            this.xiangqingform.idAndNum[j].id = this.personlist[i].username;
          }
        }
      }

    },
    up(row){
      if(this.processUser.length > 0){
        this.form.id = this.processUser[0].id;
        this.form.gongxu = row.gongxuName;
        this.num = row.num;
        this.form.gongjianid = row.id;
        this.form.tap = row.gongxuTap;
        this.dialogVisible = true
      } else {
        this.$notify.error({
          title: '错误',
          message: "本工序未配置对应加工人，请先进行配置"
        });
      }
    },
    NoUpDate(row){
      this.form.Noid = this.user.Noid;
      this.form.scrapLabels = row.scrapLabels;
      this.form.scrapNumbers = row.scrapNumbers;
      this.form.scrapNotes = row.scrapNotes;
      this.NoUp = true
    },
    NoUpDateSave(){
      request.post("/NonConformingProductsUpDate", this.NoUpDateform).then(res => {
        if (res.code == "200") {
          this.$notify({
            title: '成功',
            message: '修改成功',
            type: 'success'
          });
          //this.tableData.push(this.form)
          this.NoUp = false
          this.NoUpDateform = {}
          this.select(this.process)
        } else {
          this.$notify.error({
            title: '错误',
            message: res.code + ":" + res.msg
          });
        }
      })
    },
    Nodel(id){
      request.post("/NonConformingProductsDel", id).then(res => {
        if (res.code == "200") {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          });
          this.XQshow = false
          this.select(this.process)
        } else {
          this.$notify.error({
            title: '错误',
            message: res.code + ":" + res.msg
          });
        }
      })
    },
    //编辑表单回显
    feijianAdd(row){
      this.dialogVisible2 = true
      this.feijianform.gongjianid = row.id;
      this.feijianform.gongxu = this.process;
    },
    feijianSave(){
      if (this.feijianform){
        let err = ""
        if (this.feijianform.scrapLabels === undefined || this.feijianform.scrapLabels === null || this.feijianform.scrapLabels === ""){
          err += (err===""?"":",")+"废件标签"
        }
        if ((this.feijianform.yuangongid === undefined || this.feijianform.yuangongid === null || this.feijianform.yuangongid === "") && this.feijianform.scrapLabels!=="料废"){
          err += (err===""?"":",")+"加工人"
        }
        if (this.feijianform.scrapNumbers === undefined || this.feijianform.scrapNumbers === null || this.feijianform.scrapNumbers === "" || this.feijianform.scrapNumbers === 0){
          err += (err===""?"":",")+"废件数"
        }
        if (err !== ""){
          this.$notify.error({
            title: '错误',
            message: '请填写'+err
          });
          return
        }
      }
      if (this.process == "检查"){
        request.post("/errorintByInspector", this.feijianform).then(res => {
          if (res.code == "200") {
            this.$notify({
              title: '成功',
              message: '填报成功',
              type: 'success'
            });
            //this.tableData.push(this.form)
            this.dialogVisible2 = false
            this.XQshow = false
            this.feijianform = {}
            this.select(this.process)
          } else {
            this.$notify.error({
              title: '错误',
              message: res.code + ":" + res.msg
            });
          }
        })
      }else {
        request.post("/errorint", this.feijianform).then(res => {
          if (res.code == "200") {
            this.$notify({
              title: '成功',
              message: '填报成功',
              type: 'success'
            });
            //this.tableData.push(this.form)
            this.dialogVisible2 = false
            this.XQshow = false
            this.feijianform = {}
            this.select(this.process)
          } else {
            this.$notify.error({
              title: '错误',
              message: res.code + ":" + res.msg
            });
          }
        })
      }
    },
    //保存
    save(num) {

      if(this.$refs.cmdbProgress.saveOut()){
        if (this.tapList.length !== 0 && (this.form.tap == null || this.form.tap === "无")){
          this.$notify.error({
            title: '错误',
            message: '请选择工序标签'
          });
        } else {
          this.form.num = num;
          request.post("/ProcessingFillIn", this.form).then(res => {
            if (res.code == "200") {
              this.$notify({
                title: '成功',
                message: '修改成功',
                type: 'success'
              });
              //this.tableData.push(this.form)
              this.dialogVisible = false
              this.XQshow = false
              this.form = {}
              this.select(this.process)
            } else {
              this.$notify.error({
                title: '错误',
                message: res.code + ":" + res.msg
              });
            }
          })
        }
      }

    },
    //编辑
    /*update() {
      this.form.classList = this.form.classList.toString()
      request.post("/connect/update", this.form).then(res => {
        if (res.code == "200") {
          this.$notify({
            title: '成功',
            message: '修改成功',
            type: 'success'
          });
          this.dialogVisible = false
          this.form = {}
          request.post("/connect/select").then(res => {
            this.tableData = res.data
          })
        } else {
          this.$notify.error({
            title: '错误',
            message: res.code + ":" + res.msg
          });
        }
      })
    },*/
    //查询工序
    selectProcess(){
      request.post("/getLeadingPr",this.user.id).then(res =>{
        if (res.data.length == 0){
          request.post("/getAllPr").then(res =>{
            this.processList = res.data
            this.select(res.data[0])
            this.process = res.data[0]
            this.getUserByProcess(res.data[0])
            this.getPrTap(this.process)
          })
        } else {
          this.processList = res.data
          this.select(res.data[0])
          this.process = res.data[0]
          this.getUserByProcess(res.data[0])
          this.getPrTap(this.process)
        }
      })
    },
    //整表查询
    getPrTap(pre){
      request.post("/getPrTap",pre).then(res =>{
        this.tapList = res.data
      })
    },
    //查询
    getPersonlist(){
      request.post("/getPersonlist").then(res =>{
        this.personlist = res.data
      })
    },
    //整表查询
    select(process){

      showFullScreenLoading('.right')
      this.tableData = []
      request.post("/getlistAllByPage", {pro: process , id: this.user.id , page: this.currentPage4 , size: this.size}).then(res =>{
        this.tableData = res.data
      })
      request.post("/getlistAllSize",process).then(res =>{
        this.total = res.data
      })
      this.getPrTap(process)
      hideFullScreenLoading()
      this.getUserByProcess(process)
    },
    //关键字查询
    /*selectkey(){
      if (parseInt(this.search) >0 ){
        request.post("/connect/selectid",parseInt(this.search)).then(res =>{
          this.tableData = res.data
        })
      } else {
        request.post("/connect/selectkey",this.search).then(res =>{
          this.tableData = res.data
        })
      }
    },
    //重置
    remove(){
      this.search=''
      this.select();
    },*/
    handleSizeChange(val) {
      this.size = val;
      request.post("/getlistAllByPage", {pro: this.process , id: this.user.id , page: this.currentPage4 , size: this.size}).then(res =>{
        this.tableData = res.data
      })
      //console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.currentPage4 = val
      request.post("/getlistAllByPage", {pro: this.process , id: this.user.id , page: this.currentPage4 , size: this.size}).then(res =>{
        this.tableData = res.data
      })//console.log(`当前页: ${val}`);
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
    },
  }
}

</script>

<style scoped>
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both
}

.box-card {
  width: 480px;
}

</style>